const config = {
    api: {
        base: 'https://api-production.boxnow.gr/api/v1',
    },
    sentry: {
        dsn: 'https://83ac969954c44532b2222202c8a71c49@o1006836.ingest.sentry.io/5980663',
    },
    trackBoxNowUrl: 'https://track.boxnow.gr/',
    devTools: false,
};

export default config;
